import React from "react"
import NavBar from "../../../components/header/nav-main"
import Helmet from "react-helmet"

const Go = () => {
  return (
    <>
      <Helmet>
        <meta name="go-import" content="solace.dev/go/messaging-trace/opentelemetry git https://github.com/SolaceProducts/pubsubplus-opentelemetry-go-integration"></meta>
        <meta name="go-source" content="solace.dev/go/messaging-trace/opentelemetry https://github.com/SolaceProducts/pubsubplus-opentelemetry-go-integration https://github.com/SolaceProducts/pubsubplus-opentelemetry-go-integration/tree/main{/dir} https://github.com/SolaceProducts/pubsubplus-opentelemetry-go-integration/tree/main{/dir}/{file}#L{line}"></meta>
        <meta name="robots" content="noindex"></meta>
        <meta http-equiv="refresh" content="0;url=https://docs.solace.com/API/API-Developer-Guide-Go/Go-API-Distributed-Tracing.htm"></meta>
      </Helmet>
      <header>
        <NavBar/>
      </header>
    </>
  )
}

export default Go
